import React from 'react';

import Intro from './Intro';
import Presentation from './Presentation';
import Customers from './Customers';

const FrontPage = () => (
  <>
    <Intro />
    {/* Skip cheezy services section for now
    <Presentation />
    <Services />
    <Customers />
    */}
  </>
);

export default FrontPage;
