import React from 'react';

import { FrontPage } from '../containers';
import { SEO } from '../components';

const FrontPageContainer = () => (
  <>
    <SEO page="index" />
    <FrontPage />
  </>
);

export default FrontPageContainer;
