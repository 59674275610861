import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/styles/makeStyles';

const barVars = {
  amount: 9,
  strokeWidth: 10,
  width: 120,
  marginHorizontal: 15,
  paddingVertical: 10,
};

const animationVars = {
  initialTime: 0.2,
  timeBetween: 0.15,
  dropHeight: -200,
};

const side = barVars.strokeWidth + barVars.paddingVertical;
const height = barVars.amount * side - barVars.paddingVertical;
const width = barVars.width + barVars.marginHorizontal;

const useStyles = makeStyles(({ breakpoints }) => {
  const initialStyle = {
    svgContainer: {
      minWidth: width,
      overflow: 'visible',
      [breakpoints.down('xs')]: {
        position: 'absolute',
        opacity: 0.15,
      },
    },

    '@keyframes dropDown': {
      '0%': {
        transform: `translate(0, ${animationVars.dropHeight}px)`,
      },
      '75%': {
        opacity: 1,
      },
      '100%': {
        opacity: 1,
        transform: 'translate(0,0)',
      },
    },
  };

  const styles = initialStyle;
  for (let i = 0; i < barVars.amount; i += 1) {
    const delay =
      (barVars.amount - i) * animationVars.timeBetween +
      animationVars.initialTime;
    styles[`bar${i}`] = {
      animationName: '$dropDown',
      animationDuration: '.5s',
      animationFillMode: 'forwards',
      animationDelay: `${delay}s`,
      opacity: 0,
    };
  }
  return styles;
});

const AnimatedLogo = () => {
  const [bars, setBars] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    for (let i = 0; i < barVars.amount; i += 1) {
      const y = side * i + barVars.strokeWidth / 2;
      const x1 = i % 2 ? barVars.marginHorizontal : 0;
      const x2 =
        i % 2 ? barVars.marginHorizontal + barVars.width : barVars.width;
      setBars(prevState => [
        ...prevState,
        <line
          key={`bar${i}`}
          className={classes[`bar${i}`]}
          strokeWidth={barVars.strokeWidth}
          x1={x1}
          y1={y}
          x2={x2}
          y2={y}
        />,
      ]);
    }
  }, []);

  return (
    <svg
      width={width}
      height={height}
      stroke="white"
      className={classes.svgContainer}
    >
      {bars.map(bar => bar)}
    </svg>
  );
};

export default AnimatedLogo;
