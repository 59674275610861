import styled from '@material-ui/styles/styled';
import Container from '@material-ui/core/Container';

const IntroBackground = styled(Container)(({ image }) => ({
  backgroundImage: `url(${image})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '75% 100%',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export default IntroBackground;
