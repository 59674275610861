import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import useStyles from '../../../hooks/useStyles';
import AnimatedText from './AnimatedText';
import AnimatedLogo from './AnimatedLogo';
import IntroBackground from './IntroBackground';

const Intro = () => {
  const {
    file: {
      childMarkdownRemark: {
        frontmatter: { image },
      },
    },
  } = useStaticQuery(graphql`
    query introBackground {
      file(relativePath: { eq: "introBackground.md" }) {
        childMarkdownRemark {
          frontmatter {
            image {
              publicURL
            }
          }
        }
      }
    }
  `);
  const classes = useStyles();
  return (
    <IntroBackground maxWidth={false} image={image.publicURL}>
      <div className={classes.animationContainer}>
        <AnimatedLogo />
        <AnimatedText />
      </div>
    </IntroBackground>
  );
};

export default Intro;
