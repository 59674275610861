import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useStaticQuery, graphql } from 'gatsby';

import { useStyles, useLanguage } from '../../../hooks';

const AnimatedText = () => {
  const classes = useStyles();
  const language = useLanguage();
  const {
    allFile: { nodes },
  } = useStaticQuery(graphql`
    query {
      allFile(filter: { name: { eq: "tagline" } }) {
        nodes {
          childMarkdownRemark {
            headings {
              value
              depth
            }
          }
          relativeDirectory
        }
      }
    }
  `);

  const renderDeptTwo = text => (
    <Typography variant="h3" component="h2" color="secondary" key={text}>
      {text}
    </Typography>
  );
  const renderDeptOne = text => (
    <Typography
      variant="h2"
      component="h1"
      color="secondary"
      style={{ textTransform: 'uppercase' }}
      key={text}
    >
      {text}
    </Typography>
  );

  const {
    childMarkdownRemark: { headings },
  } = nodes.filter(el => el.relativeDirectory === language)[0];

  return (
    <div className={`${classes.introText} ${classes.fadeInZoom}`}>
      {headings.map(heading => {
        switch (heading.depth) {
          case 1:
            return renderDeptOne(heading.value);
          default:
            return renderDeptTwo(heading.value);
        }
      })}
    </div>
  );
};
export default AnimatedText;
